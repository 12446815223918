<template>
  <div class="popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5 col-xxl-4  p-5">
        <div class="popup_inner">
          <div class="overlay"></div>
          <div class="row mx-0 above">
            <div class="col-12 p-0">
              <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
              <div class="row p-4">
                <div class="col-12 p-0">
                  <div class="row m-0">
                    <div class="col-12 mb-3 text-center smallheading" v-if="title">
                      {{ title }}
                    </div>
                    <div class="col-12 description medium" :class="align ? 'text-' + align : 'text-center'" v-if="message" v-html="message" />
                  </div>
                </div>
                <div class="col-12 p-0 mt-4" v-if="button1 || button2">
                  <div class="row m-0">
                    <div :class="{ 'col-6' : button2, 'col-12' : !button2 }"  class="text-center" >
                      <Button color="red" :btnText="button1" icon="arrow" @buttonClicked="button1Action" class="w-100">
                        <ios-trash-icon class="inline-icon small"/>
                      </Button>
                    </div>
                    <div class="col-6 text-center" @click="button2Action" v-if="button2" :class="{ 'isClicked': isClicked2 }">
                      <Button color="green" :btnText="button2" icon="arrow" @buttonClicked="button2Action" class="w-100" >
                        <ios-close-icon class="inline-icon"/>
                      </Button>
                    </div>
                    <!-- <div :class="{ 'isClicked': isClicked1, 'col-6' : button2, 'col-12' : !button2 }"  class="alert_btn_left p-3 text-center" @click="button1Action">
                      {{ button1 }}
                    </div>
                    <div class="col-6 alert_btn_right p-3 text-center" @click="button2Action" v-if="button2" :class="{ 'isClicked': isClicked2 }">
                      {{ button2 }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'Alert',
  props: ['title', 'message', 'button1', 'button2', 'align'],
  data: function () {
    return {
      isClicked1: false,
      isClicked2: false
    }
  },
  methods: {
    button1Action () {
      var self = this
      this.isClicked1 = true
      setTimeout(function () {
        self.isClicked1 = false
        self.$emit('doAction1')
      }, 100)
    },
    button2Action () {
      var self = this
      this.isClicked2 = true
      setTimeout(function () {
        self.isClicked2 = false
        self.$emit('doAction2')
      }, 100)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert_btn_right {
  font-size: 0.85rem;
  border-bottom-right-radius: 25px;
}
.alert_btn_left {
  font-size: 0.85rem;
  border-bottom-left-radius: 25px;
}
.description {
  line-height: 1.2;
  font-size: 0.95rem;
}
.bottom_of {
  position: absolute;
  bottom: 0;
  width: calc(100% - 30px);
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.fill {
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
  }
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    z-index: 99;
  }
  .popup_inner  {
    animation-duration: 1.2s;
    border-radius: 22px 22px 22px 22px;
    box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
    margin: 0;
    max-height: 80vh;
    overflow: hidden;
    background-color: #FFFFFF;
    background-image: url(../../../../assets/images/bg.png);
    position: relative;
    padding: 1rem;
  }
  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFFF7 0%, #FFFFFF 100%);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .above {
    position: relative;
    z-index: 2;
    color: var(--normal-color-dark);
    max-height: calc(80vh - 2rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .heading {
    color: var( --green-color-dark );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 44px;
    font-weight: 600;
    line-height: 1.5em;
  }
  .smallheading {
    color: var( --green-color );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    color: var( --green-color );
    font-size: 2.5rem;
  }
</style>
